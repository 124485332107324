import {Injectable} from '@angular/core';
import environment from '../../environment/index.js';
import {ApiService} from './api.service';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GazetteService {
  public url: BehaviorSubject<string | null> =
    new BehaviorSubject(null);

  constructor(protected Api: ApiService, protected authService: AuthService) {
    authService.isAuthenticated.subscribe(isLoggedIn => isLoggedIn && this.load());
  }

  public load() {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/gazette`
    ).then(data => {
      this.url.next(data);
    });
  }
}
