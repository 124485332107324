
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './angular/modules/homepage/components/app.component';
import { TestComponent } from './angular/modules/test/components/app.component';
import { ClubsComponent } from './angular/modules/account/clubs/app.component';
import { AccountComponent } from './angular/modules/account/app.component';
import { AddressComponent } from './angular/modules/account/address/app.component';

const routes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'Users/my-profile', component: AccountComponent},
  {path: 'Users/my-clubs', component: ClubsComponent},
  {path: 'Users/address', component: AddressComponent},
  {
    path: '**',
    component: TestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
