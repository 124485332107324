<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>Change password</h3>
  </section>
  <section mat-dialog-content>
    <mat-form-field>
      <mat-placeholder>Current Password:</mat-placeholder>
      <input
        type="password"
        matInput
        formControlName="passwordCurrent"
        id="passwordCurrent"
        autocomplete="current-password"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>New Password:</mat-placeholder>
      <input
        type="password"
        matInput
        formControlName="password"
        id="password"
        autocomplete="new-password"
        required
      />
    </mat-form-field>

    <mat-form-field>
      <mat-placeholder>Confirm Password:</mat-placeholder>
      <input
        type="password"
        matInput
        formControlName="passwordConfirm"
        id="passwordConfirm"
        autocomplete="new-password"
        required
      />
    </mat-form-field>
    <p class="form-error" [hidden]="!form.hasError('passwordError')">
      Passwords do not match
    </p>
    <p class="form-error" [hidden]="!form.hasError('passwordCurrentError')">
      New password cannot be the same as the current password
    </p>
    <p *ngIf="invalidPassword" class="form-error">
      {{ invalidPassword }}
    </p>
  </section>
  <section align="end" mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      matTooltip="Cancel and close."
      type="button"
    >
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button
      matTooltip="Save product info."
      [disabled]="
        form.hasError('passwordError') || form.hasError('passwordCurrentError')
      "
      type="submit"
    >
      Confirm
    </button>
  </section>
</form>
