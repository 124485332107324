<div
  id="accountModal"
  class="right-panel modal"
  data-augeo-scroll="page.scrollPanelOptions"
  data-scroll-object="page.scroll.right"
>
  <nav>
    <ul class="menu">
      <li>
        <a title="" (click)="navigate('/Users/my-profile')">
          <span class="fa fa-fw fa-user"></span> Account
        </a>
      </li>
      <li *ngIf="isAdmin">
        <a (click)="navigate('admin')">
          <span class="fa fa-fw fa-user-secret"></span> Admin
        </a>
      </li>
      <li class="divider"></li>
      <li>
        <a href="https://jlpleisurebenefits.zendesk.com/home" title="">
          <span class="fa fa-fw fa-life-ring"></span> Help
        </a>
      </li>
      <li class="divider"></li>
      <li>
        <a title="" (click)="navigate('/Logout')">
          <span class="fa fa-fw fa-sign-out"></span> Sign Out
        </a>
      </li>
      <div class="hide-desktop">
        <li class="divider"></li>
        <li class="hide-desktop">
          <a
            href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
          >
            <span class="fa fa-fw fa-lg fa-brands fa-apple"></span> Apple App
          </a>
        </li>
        <li class="divider"></li>
        <li class="hide-desktop">
          <a
            href="https://www.partnerchoice.co.uk/listing/9216/partnerchoice-app"
          >
            <span class="fa fa-fw fa-lg fa-brands fa-android"></span> Android
            App
          </a>
        </li>
      </div>
    </ul>
  </nav>
</div>
