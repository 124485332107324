export const zdUrl = 'https://jlpleisurebenefits.zendesk.com';
export const zdApiUrl = `${zdUrl}/api/v2`;

export const preferences = [
  'accept_terms_and_conditions',
  'receive_marketing_emails',
];
export const tsAndCs = {
  ticket: `${zdUrl}/hc/articles/202105642`,
  product: `${zdUrl}/hc/articles/360025818334`,
  booking: `${zdUrl}/hc/articles/204848462`,
  odney: `${zdUrl}/hc/articles/360019228493`,
  website: `${zdUrl}/hc/articles/115002889609`,
};
export const welcomePageUrl = 'listing/7199/welcome-to-partnerchoice';
export const receiveEmails = {
  label: `Yes, I would like to receive information about Partnership events, flash sales, Partner extravaganzas, Club news, offers, tickets and so much more...`,
  text: `We would like to send you information about products, services, tickets and events of ours and other companies that we offer benefits through, which may be of interest to you. You have a right at any time to stop us from contacting you for marketing purposes.`,
};
