import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../../services/user.service';
import {AddressService} from '../../../../../services/address.service';
@Component({
  selector: 'app-details',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AddressDeleteModalComponent {
  public user;
  public loading = false;
  public showEmailFields: boolean = false;
  public invalidPassword;

  constructor(
    protected dialogRef: MatDialogRef<AddressDeleteModalComponent>,
    private userService: UserService,
    private addressService: AddressService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.userService.User.subscribe(({User}) => {
      this.user = User;
    });
  }

  onSubmit() {
    try {
      const {id} = this.data;
      this.addressService.deleteAddress(this.user.id, id).then(() => {
        this.dialogRef.close();
      });
    } catch (error) {
      console.log('err', error.message);
    }
  }
}
