import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import environment from '../../environment';
@Injectable()
export class AddressService {
  constructor(protected Api: ApiService) {}
  public Addresses = new BehaviorSubject([]);

  public async updateAddress(userId, data) {
    return this.Api.put(
      `${environment.settings.registeredAwsUrl}/Users/${userId}/UserAddress/${data.id}`,
      {address: data}
    );
  }
  public async addAddress(userId, data) {
    return this.Api.post(
      `${environment.settings.registeredAwsUrl}/Users/${userId}/UserAddress`,
      {address: data}
    );
  }
  public async deleteAddress(userId, id) {
    return this.Api.delete(
      `${environment.settings.registeredAwsUrl}/Users/${userId}/UserAddress/${id}`
    );
  }
  // public async getAddress(id) {
  //     return this.Api.get(
  //         `${environment.settings.registeredAwsUrl}/addresses/${id}`
  //     );
  // }
}
