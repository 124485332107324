import {Component} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'my-clubs',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class ClubsComponent {
  public clubs;
  public loading: boolean;

  constructor(
    protected userService: UserService,
    public commonService: CommonService
  ) {
    this.loading = true;

    this.userService.getUserClubs().then(({data}) => {
      this.clubs = data;
      this.loading = false;
    });
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}
