<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<div
  class="grid-layout my-clubs"
  data-infinite-scroll="List.nextPage()"
  data-infinite-scroll-distance="0"
>
  <div class="row clearfix max-width">
    <div class="small-12 columns account-row">
      <a (click)="navigate('/Users/my-profile')" title="">
        <span class="fa fa-fw fa-arrow-left"></span> Back to My Account
      </a>
    </div>
  </div>
  <div class="row clearfix max-width">
    <div class="small-12 columns">
      <div>
        <h2>My Clubs</h2>
      </div>
    </div>
  </div>

  <div class="small-12 columns" *ngIf="clubs && !clubs.length">
    You are not part of any clubs.
  </div>
  <div class="item" *ngFor="let club of clubs">
    <div
      class="item-contents"
      (click)="navigate('/company/' + club.data.company_slug)"
    >
      <div
        class="image"
        [ngStyle]="{'background-image': 'url(/CompanyImages/' + club.data.ProductImage[0].company_image_id + '/thumbnail/image.jpg)'}"
        
      >
        <div *ngIf="club.data.badge" class="badged">
          {{ club.data.badge }}
        </div>
      </div>
      <div class="description">
        <h3>{{ club.data.menu_title }}</h3>
        <p class="item-description" [innerHTML]="club.data.description"></p>
      </div>
      <div class="item-price" [ngClass]="{expired: !club.data.isMember}">
        <div>
          <p>
            Partnership Club Page<span>{{
              (club.data.isMember && 'Joined') || 'Expired'
            }}</span>
          </p>
        </div>

        <div class="view-details">
          <p>View Details <span class="fa fa-chevron-circle-right"></span></p>
        </div>
      </div>
    </div>
  </div>
</div>
