import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import enviroment from '../../../../environment/index.js';
@Component({
  selector: 'user-menu',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class UserMenuComponent {
  @Input() isAdmin;
  constructor(private router: Router, public auth: AuthService) {}

  public navigate(path) {
    if (path === '/Logout') {
      this.auth.logout();
    } else if (path === 'admin') {
      window.location.href = enviroment.settings.adminUrl;
    } else {
      this.router.navigate([path]).then(() => {
        window.location.reload();
      });
    }
  }
}
