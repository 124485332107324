const countries = [
  {shortName: 'Afghanistan', twoLetterCode: 'AF', threeLetterCode: 'AFG'},
  {shortName: 'Åland Islands', twoLetterCode: 'AX', threeLetterCode: 'ALA'},
  {shortName: 'Albania', twoLetterCode: 'AL', threeLetterCode: 'ALB'},
  {shortName: 'Algeria', twoLetterCode: 'DZ', threeLetterCode: 'DZA'},
  {shortName: 'American Samoa', twoLetterCode: 'AS', threeLetterCode: 'ASM'},
  {shortName: 'Andorra', twoLetterCode: 'AD', threeLetterCode: 'AND'},
  {shortName: 'Angola', twoLetterCode: 'AO', threeLetterCode: 'AGO'},
  {shortName: 'Anguilla', twoLetterCode: 'AI', threeLetterCode: 'AIA'},
  {shortName: 'Antarctica', twoLetterCode: 'AQ', threeLetterCode: 'ATA'},
  {
    shortName: 'Antigua and Barbuda',
    twoLetterCode: 'AG',
    threeLetterCode: 'ATG',
  },
  {shortName: 'Argentina', twoLetterCode: 'AR', threeLetterCode: 'ARG'},
  {shortName: 'Armenia', twoLetterCode: 'AM', threeLetterCode: 'ARM'},
  {shortName: 'Aruba', twoLetterCode: 'AW', threeLetterCode: 'ABW'},
  {shortName: 'Australia', twoLetterCode: 'AU', threeLetterCode: 'AUS'},
  {shortName: 'Austria', twoLetterCode: 'AT', threeLetterCode: 'AUT'},
  {shortName: 'Azerbaijan', twoLetterCode: 'AZ', threeLetterCode: 'AZE'},
  {shortName: 'Bahamas', twoLetterCode: 'BS', threeLetterCode: 'BHS'},
  {shortName: 'Bahrain', twoLetterCode: 'BH', threeLetterCode: 'BHR'},
  {shortName: 'Bangladesh', twoLetterCode: 'BD', threeLetterCode: 'BGD'},
  {shortName: 'Barbados', twoLetterCode: 'BB', threeLetterCode: 'BRB'},
  {shortName: 'Belarus', twoLetterCode: 'BY', threeLetterCode: 'BLR'},
  {shortName: 'Belgium', twoLetterCode: 'BE', threeLetterCode: 'BEL'},
  {shortName: 'Belize', twoLetterCode: 'BZ', threeLetterCode: 'BLZ'},
  {shortName: 'Benin', twoLetterCode: 'BJ', threeLetterCode: 'BEN'},
  {shortName: 'Bermuda', twoLetterCode: 'BM', threeLetterCode: 'BMU'},
  {shortName: 'Bhutan', twoLetterCode: 'BT', threeLetterCode: 'BTN'},
  {
    shortName: 'Bolivia, Plurinational State of',
    twoLetterCode: 'BO',
    threeLetterCode: 'BOL',
  },
  {
    shortName: 'Bonaire, Sint Eustatius and Saba',
    twoLetterCode: 'BQ',
    threeLetterCode: 'BES',
  },
  {
    shortName: 'Bosnia and Herzegovina',
    twoLetterCode: 'BA',
    threeLetterCode: 'BIH',
  },
  {shortName: 'Botswana', twoLetterCode: 'BW', threeLetterCode: 'BWA'},
  {shortName: 'Bouvet Island', twoLetterCode: 'BV', threeLetterCode: 'BVT'},
  {shortName: 'Brazil', twoLetterCode: 'BR', threeLetterCode: 'BRA'},
  {
    shortName: 'British Indian Ocean Territory',
    twoLetterCode: 'IO',
    threeLetterCode: 'IOT',
  },
  {
    shortName: 'Brunei Darussalam',
    twoLetterCode: 'BN',
    threeLetterCode: 'BRN',
  },
  {shortName: 'Bulgaria', twoLetterCode: 'BG', threeLetterCode: 'BGR'},
  {shortName: 'Burkina Faso', twoLetterCode: 'BF', threeLetterCode: 'BFA'},
  {shortName: 'Burundi', twoLetterCode: 'BI', threeLetterCode: 'BDI'},
  {shortName: 'Cambodia', twoLetterCode: 'KH', threeLetterCode: 'KHM'},
  {shortName: 'Cameroon', twoLetterCode: 'CM', threeLetterCode: 'CMR'},
  {shortName: 'Canada', twoLetterCode: 'CA', threeLetterCode: 'CAN'},
  {shortName: 'Cape Verde', twoLetterCode: 'CV', threeLetterCode: 'CPV'},
  {shortName: 'Cayman Islands', twoLetterCode: 'KY', threeLetterCode: 'CYM'},
  {
    shortName: 'Central African Republic',
    twoLetterCode: 'CF',
    threeLetterCode: 'CAF',
  },
  {shortName: 'Chad', twoLetterCode: 'TD', threeLetterCode: 'TCD'},
  {shortName: 'Chile', twoLetterCode: 'CL', threeLetterCode: 'CHL'},
  {shortName: 'China', twoLetterCode: 'CN', threeLetterCode: 'CHN'},
  {
    shortName: 'Christmas Island',
    twoLetterCode: 'CX',
    threeLetterCode: 'CXR',
  },
  {
    shortName: 'Cocos (Keeling) Islands',
    twoLetterCode: 'CC',
    threeLetterCode: 'CCK',
  },
  {shortName: 'Colombia', twoLetterCode: 'CO', threeLetterCode: 'COL'},
  {shortName: 'Comoros', twoLetterCode: 'KM', threeLetterCode: 'COM'},
  {shortName: 'Congo', twoLetterCode: 'CG', threeLetterCode: 'COG'},
  {
    shortName: 'Congo, the Democratic Republic of the',
    twoLetterCode: 'CD',
    threeLetterCode: 'COD',
  },
  {shortName: 'Cook Islands', twoLetterCode: 'CK', threeLetterCode: 'COK'},
  {shortName: 'Costa Rica', twoLetterCode: 'CR', threeLetterCode: 'CRI'},
  {shortName: "Côte d'Ivoire", twoLetterCode: 'CI', threeLetterCode: 'CIV'},
  {shortName: 'Croatia', twoLetterCode: 'HR', threeLetterCode: 'HRV'},
  {shortName: 'Cuba', twoLetterCode: 'CU', threeLetterCode: 'CUB'},
  {shortName: 'Curaçao', twoLetterCode: 'CW', threeLetterCode: 'CUW'},
  {shortName: 'Cyprus', twoLetterCode: 'CY', threeLetterCode: 'CYP'},
  {shortName: 'Czech Republic', twoLetterCode: 'CZ', threeLetterCode: 'CZE'},
  {shortName: 'Denmark', twoLetterCode: 'DK', threeLetterCode: 'DNK'},
  {shortName: 'Djibouti', twoLetterCode: 'DJ', threeLetterCode: 'DJI'},
  {shortName: 'Dominica', twoLetterCode: 'DM', threeLetterCode: 'DMA'},
  {
    shortName: 'Dominican Republic',
    twoLetterCode: 'DO',
    threeLetterCode: 'DOM',
  },
  {shortName: 'Ecuador', twoLetterCode: 'EC', threeLetterCode: 'ECU'},
  {shortName: 'Egypt', twoLetterCode: 'EG', threeLetterCode: 'EGY'},
  {shortName: 'El Salvador', twoLetterCode: 'SV', threeLetterCode: 'SLV'},
  {
    shortName: 'Equatorial Guinea',
    twoLetterCode: 'GQ',
    threeLetterCode: 'GNQ',
  },
  {shortName: 'Eritrea', twoLetterCode: 'ER', threeLetterCode: 'ERI'},
  {shortName: 'Estonia', twoLetterCode: 'EE', threeLetterCode: 'EST'},
  {shortName: 'Ethiopia', twoLetterCode: 'ET', threeLetterCode: 'ETH'},
  {
    shortName: 'Falkland Islands (Malvinas)',
    twoLetterCode: 'FK',
    threeLetterCode: 'FLK',
  },
  {shortName: 'Faroe Islands', twoLetterCode: 'FO', threeLetterCode: 'FRO'},
  {shortName: 'Fiji', twoLetterCode: 'FJ', threeLetterCode: 'FJI'},
  {shortName: 'Finland', twoLetterCode: 'FI', threeLetterCode: 'FIN'},
  {shortName: 'France', twoLetterCode: 'FR', threeLetterCode: 'FRA'},
  {shortName: 'French Guiana', twoLetterCode: 'GF', threeLetterCode: 'GUF'},
  {
    shortName: 'French Polynesia',
    twoLetterCode: 'PF',
    threeLetterCode: 'PYF',
  },
  {
    shortName: 'French Southern Territories',
    twoLetterCode: 'TF',
    threeLetterCode: 'ATF',
  },
  {shortName: 'Gabon', twoLetterCode: 'GA', threeLetterCode: 'GAB'},
  {shortName: 'Gambia', twoLetterCode: 'GM', threeLetterCode: 'GMB'},
  {shortName: 'Georgia', twoLetterCode: 'GE', threeLetterCode: 'GEO'},
  {shortName: 'Germany', twoLetterCode: 'DE', threeLetterCode: 'DEU'},
  {shortName: 'Ghana', twoLetterCode: 'GH', threeLetterCode: 'GHA'},
  {shortName: 'Gibraltar', twoLetterCode: 'GI', threeLetterCode: 'GIB'},
  {shortName: 'Greece', twoLetterCode: 'GR', threeLetterCode: 'GRC'},
  {shortName: 'Greenland', twoLetterCode: 'GL', threeLetterCode: 'GRL'},
  {shortName: 'Grenada', twoLetterCode: 'GD', threeLetterCode: 'GRD'},
  {shortName: 'Guadeloupe', twoLetterCode: 'GP', threeLetterCode: 'GLP'},
  {shortName: 'Guam', twoLetterCode: 'GU', threeLetterCode: 'GUM'},
  {shortName: 'Guatemala', twoLetterCode: 'GT', threeLetterCode: 'GTM'},
  {shortName: 'Guernsey', twoLetterCode: 'GG', threeLetterCode: 'GGY'},
  {shortName: 'Guinea', twoLetterCode: 'GN', threeLetterCode: 'GIN'},
  {shortName: 'Guinea-Bissau', twoLetterCode: 'GW', threeLetterCode: 'GNB'},
  {shortName: 'Guyana', twoLetterCode: 'GY', threeLetterCode: 'GUY'},
  {shortName: 'Haiti', twoLetterCode: 'HT', threeLetterCode: 'HTI'},
  {
    shortName: 'Heard Island and McDonald Islands',
    twoLetterCode: 'HM',
    threeLetterCode: 'HMD',
  },
  {
    shortName: 'Holy See (Vatican City State)',
    twoLetterCode: 'VA',
    threeLetterCode: 'VAT',
  },
  {shortName: 'Honduras', twoLetterCode: 'HN', threeLetterCode: 'HND'},
  {shortName: 'Hong Kong', twoLetterCode: 'HK', threeLetterCode: 'HKG'},
  {shortName: 'Hungary', twoLetterCode: 'HU', threeLetterCode: 'HUN'},
  {shortName: 'Iceland', twoLetterCode: 'IS', threeLetterCode: 'ISL'},
  {shortName: 'India', twoLetterCode: 'IN', threeLetterCode: 'IND'},
  {shortName: 'Indonesia', twoLetterCode: 'ID', threeLetterCode: 'IDN'},
  {
    shortName: 'Iran, Islamic Republic of',
    twoLetterCode: 'IR',
    threeLetterCode: 'IRN',
  },
  {shortName: 'Iraq', twoLetterCode: 'IQ', threeLetterCode: 'IRQ'},
  {shortName: 'Ireland', twoLetterCode: 'IE', threeLetterCode: 'IRL'},
  {shortName: 'Isle of Man', twoLetterCode: 'IM', threeLetterCode: 'IMN'},
  {shortName: 'Israel', twoLetterCode: 'IL', threeLetterCode: 'ISR'},
  {shortName: 'Italy', twoLetterCode: 'IT', threeLetterCode: 'ITA'},
  {shortName: 'Jamaica', twoLetterCode: 'JM', threeLetterCode: 'JAM'},
  {shortName: 'Japan', twoLetterCode: 'JP', threeLetterCode: 'JPN'},
  {shortName: 'Jersey', twoLetterCode: 'JE', threeLetterCode: 'JEY'},
  {shortName: 'Jordan', twoLetterCode: 'JO', threeLetterCode: 'JOR'},
  {shortName: 'Kazakhstan', twoLetterCode: 'KZ', threeLetterCode: 'KAZ'},
  {shortName: 'Kenya', twoLetterCode: 'KE', threeLetterCode: 'KEN'},
  {shortName: 'Kiribati', twoLetterCode: 'KI', threeLetterCode: 'KIR'},
  {
    shortName: "Korea, Democratic People's Republic of",
    twoLetterCode: 'KP',
    threeLetterCode: 'PRK',
  },
  {
    shortName: 'Korea, Republic of',
    twoLetterCode: 'KR',
    threeLetterCode: 'KOR',
  },
  {shortName: 'Kuwait', twoLetterCode: 'KW', threeLetterCode: 'KWT'},
  {shortName: 'Kyrgyzstan', twoLetterCode: 'KG', threeLetterCode: 'KGZ'},
  {
    shortName: "Lao People's Democratic Republic",
    twoLetterCode: 'LA',
    threeLetterCode: 'LAO',
  },
  {shortName: 'Latvia', twoLetterCode: 'LV', threeLetterCode: 'LVA'},
  {shortName: 'Lebanon', twoLetterCode: 'LB', threeLetterCode: 'LBN'},
  {shortName: 'Lesotho', twoLetterCode: 'LS', threeLetterCode: 'LSO'},
  {shortName: 'Liberia', twoLetterCode: 'LR', threeLetterCode: 'LBR'},
  {shortName: 'Libya', twoLetterCode: 'LY', threeLetterCode: 'LBY'},
  {shortName: 'Liechtenstein', twoLetterCode: 'LI', threeLetterCode: 'LIE'},
  {shortName: 'Lithuania', twoLetterCode: 'LT', threeLetterCode: 'LTU'},
  {shortName: 'Luxembourg', twoLetterCode: 'LU', threeLetterCode: 'LUX'},
  {shortName: 'Macao', twoLetterCode: 'MO', threeLetterCode: 'MAC'},
  {
    shortName: 'Macedonia, The Former Yugoslav Republic of',
    twoLetterCode: 'MK',
    threeLetterCode: 'MKD',
  },
  {shortName: 'Madagascar', twoLetterCode: 'MG', threeLetterCode: 'MDG'},
  {shortName: 'Malawi', twoLetterCode: 'MW', threeLetterCode: 'MWI'},
  {shortName: 'Malaysia', twoLetterCode: 'MY', threeLetterCode: 'MYS'},
  {shortName: 'Maldives', twoLetterCode: 'MV', threeLetterCode: 'MDV'},
  {shortName: 'Mali', twoLetterCode: 'ML', threeLetterCode: 'MLI'},
  {shortName: 'Malta', twoLetterCode: 'MT', threeLetterCode: 'MLT'},
  {
    shortName: 'Marshall Islands',
    twoLetterCode: 'MH',
    threeLetterCode: 'MHL',
  },
  {shortName: 'Martinique', twoLetterCode: 'MQ', threeLetterCode: 'MTQ'},
  {shortName: 'Mauritania', twoLetterCode: 'MR', threeLetterCode: 'MRT'},
  {shortName: 'Mauritius', twoLetterCode: 'MU', threeLetterCode: 'MUS'},
  {shortName: 'Mayotte', twoLetterCode: 'YT', threeLetterCode: 'MYT'},
  {shortName: 'Mexico', twoLetterCode: 'MX', threeLetterCode: 'MEX'},
  {
    shortName: 'Micronesia, Federated States of',
    twoLetterCode: 'FM',
    threeLetterCode: 'FSM',
  },
  {
    shortName: 'Moldova, Republic of',
    twoLetterCode: 'MD',
    threeLetterCode: 'MDA',
  },
  {shortName: 'Monaco', twoLetterCode: 'MC', threeLetterCode: 'MCO'},
  {shortName: 'Mongolia', twoLetterCode: 'MN', threeLetterCode: 'MNG'},
  {shortName: 'Montenegro', twoLetterCode: 'ME', threeLetterCode: 'MNE'},
  {shortName: 'Montserrat', twoLetterCode: 'MS', threeLetterCode: 'MSR'},
  {shortName: 'Morocco', twoLetterCode: 'MA', threeLetterCode: 'MAR'},
  {shortName: 'Mozambique', twoLetterCode: 'MZ', threeLetterCode: 'MOZ'},
  {shortName: 'Myanmar', twoLetterCode: 'MM', threeLetterCode: 'MMR'},
  {shortName: 'Namibia', twoLetterCode: 'NA', threeLetterCode: 'NAM'},
  {shortName: 'Nauru', twoLetterCode: 'NR', threeLetterCode: 'NRU'},
  {shortName: 'Nepal', twoLetterCode: 'NP', threeLetterCode: 'NPL'},
  {shortName: 'Netherlands', twoLetterCode: 'NL', threeLetterCode: 'NLD'},
  {shortName: 'New Caledonia', twoLetterCode: 'NC', threeLetterCode: 'NCL'},
  {shortName: 'New Zealand', twoLetterCode: 'NZ', threeLetterCode: 'NZL'},
  {shortName: 'Nicaragua', twoLetterCode: 'NI', threeLetterCode: 'NIC'},
  {shortName: 'Niger', twoLetterCode: 'NE', threeLetterCode: 'NER'},
  {shortName: 'Nigeria', twoLetterCode: 'NG', threeLetterCode: 'NGA'},
  {shortName: 'Niue', twoLetterCode: 'NU', threeLetterCode: 'NIU'},
  {shortName: 'Norfolk Island', twoLetterCode: 'NF', threeLetterCode: 'NFK'},
  {
    shortName: 'Northern Mariana Islands',
    twoLetterCode: 'MP',
    threeLetterCode: 'MNP',
  },
  {shortName: 'Norway', twoLetterCode: 'NO', threeLetterCode: 'NOR'},
  {shortName: 'Oman', twoLetterCode: 'OM', threeLetterCode: 'OMN'},
  {shortName: 'Pakistan', twoLetterCode: 'PK', threeLetterCode: 'PAK'},
  {shortName: 'Palau', twoLetterCode: 'PW', threeLetterCode: 'PLW'},
  {
    shortName: 'Palestine, State of',
    twoLetterCode: 'PS',
    threeLetterCode: 'PSE',
  },
  {shortName: 'Panama', twoLetterCode: 'PA', threeLetterCode: 'PAN'},
  {
    shortName: 'Papua New Guinea',
    twoLetterCode: 'PG',
    threeLetterCode: 'PNG',
  },
  {shortName: 'Paraguay', twoLetterCode: 'PY', threeLetterCode: 'PRY'},
  {shortName: 'Peru', twoLetterCode: 'PE', threeLetterCode: 'PER'},
  {shortName: 'Philippines', twoLetterCode: 'PH', threeLetterCode: 'PHL'},
  {shortName: 'Pitcairn', twoLetterCode: 'PN', threeLetterCode: 'PCN'},
  {shortName: 'Poland', twoLetterCode: 'PL', threeLetterCode: 'POL'},
  {shortName: 'Portugal', twoLetterCode: 'PT', threeLetterCode: 'PRT'},
  {shortName: 'Puerto Rico', twoLetterCode: 'PR', threeLetterCode: 'PRI'},
  {shortName: 'Qatar', twoLetterCode: 'QA', threeLetterCode: 'QAT'},
  {shortName: 'Réunion', twoLetterCode: 'RE', threeLetterCode: 'REU'},
  {shortName: 'Romania', twoLetterCode: 'RO', threeLetterCode: 'ROU'},
  {
    shortName: 'Russian Federation',
    twoLetterCode: 'RU',
    threeLetterCode: 'RUS',
  },
  {shortName: 'Rwanda', twoLetterCode: 'RW', threeLetterCode: 'RWA'},
  {
    shortName: 'Saint Barthélemy',
    twoLetterCode: 'BL',
    threeLetterCode: 'BLM',
  },
  {
    shortName: 'Saint Helena, Ascension and Tristan da Cunha',
    twoLetterCode: 'SH',
    threeLetterCode: 'SHN',
  },
  {
    shortName: 'Saint Kitts and Nevis',
    twoLetterCode: 'KN',
    threeLetterCode: 'KNA',
  },
  {shortName: 'Saint Lucia', twoLetterCode: 'LC', threeLetterCode: 'LCA'},
  {
    shortName: 'Saint Martin (French part)',
    twoLetterCode: 'MF',
    threeLetterCode: 'MAF',
  },
  {
    shortName: 'Saint Pierre and Miquelon',
    twoLetterCode: 'PM',
    threeLetterCode: 'SPM',
  },
  {
    shortName: 'Saint Vincent and the Grenadines',
    twoLetterCode: 'VC',
    threeLetterCode: 'VCT',
  },
  {shortName: 'Samoa', twoLetterCode: 'WS', threeLetterCode: 'WSM'},
  {shortName: 'San Marino', twoLetterCode: 'SM', threeLetterCode: 'SMR'},
  {
    shortName: 'Sao Tome and Principe',
    twoLetterCode: 'ST',
    threeLetterCode: 'STP',
  },
  {shortName: 'Saudi Arabia', twoLetterCode: 'SA', threeLetterCode: 'SAU'},
  {shortName: 'Senegal', twoLetterCode: 'SN', threeLetterCode: 'SEN'},
  {shortName: 'Serbia', twoLetterCode: 'RS', threeLetterCode: 'SRB'},
  {shortName: 'Seychelles', twoLetterCode: 'SC', threeLetterCode: 'SYC'},
  {shortName: 'Sierra Leone', twoLetterCode: 'SL', threeLetterCode: 'SLE'},
  {shortName: 'Singapore', twoLetterCode: 'SG', threeLetterCode: 'SGP'},
  {
    shortName: 'Sint Maarten (Dutch part)',
    twoLetterCode: 'SX',
    threeLetterCode: 'SXM',
  },
  {shortName: 'Slovakia', twoLetterCode: 'SK', threeLetterCode: 'SVK'},
  {shortName: 'Slovenia', twoLetterCode: 'SI', threeLetterCode: 'SVN'},
  {shortName: 'Solomon Islands', twoLetterCode: 'SB', threeLetterCode: 'SLB'},
  {shortName: 'Somalia', twoLetterCode: 'SO', threeLetterCode: 'SOM'},
  {shortName: 'South Africa', twoLetterCode: 'ZA', threeLetterCode: 'ZAF'},
  {
    shortName: 'South Georgia and the South Sandwich Islands',
    twoLetterCode: 'GS',
    threeLetterCode: 'SGS',
  },
  {shortName: 'South Sudan', twoLetterCode: 'SS', threeLetterCode: 'SSD'},
  {shortName: 'Spain', twoLetterCode: 'ES', threeLetterCode: 'ESP'},
  {shortName: 'Sri Lanka', twoLetterCode: 'LK', threeLetterCode: 'LKA'},
  {shortName: 'Sudan', twoLetterCode: 'SD', threeLetterCode: 'SDN'},
  {shortName: 'Suriname', twoLetterCode: 'SR', threeLetterCode: 'SUR'},
  {
    shortName: 'Svalbard and Jan Mayen',
    twoLetterCode: 'SJ',
    threeLetterCode: 'SJM',
  },
  {shortName: 'Swaziland', twoLetterCode: 'SZ', threeLetterCode: 'SWZ'},
  {shortName: 'Sweden', twoLetterCode: 'SE', threeLetterCode: 'SWE'},
  {shortName: 'Switzerland', twoLetterCode: 'CH', threeLetterCode: 'CHE'},
  {
    shortName: 'Syrian Arab Republic',
    twoLetterCode: 'SY',
    threeLetterCode: 'SYR',
  },
  {
    shortName: 'Taiwan, Province of China',
    twoLetterCode: 'TW',
    threeLetterCode: 'TWN',
  },
  {shortName: 'Tajikistan', twoLetterCode: 'TJ', threeLetterCode: 'TJK'},
  {
    shortName: 'Tanzania, United Republic of',
    twoLetterCode: 'TZ',
    threeLetterCode: 'TZA',
  },
  {shortName: 'Thailand', twoLetterCode: 'TH', threeLetterCode: 'THA'},
  {shortName: 'Timor-Leste', twoLetterCode: 'TL', threeLetterCode: 'TLS'},
  {shortName: 'Togo', twoLetterCode: 'TG', threeLetterCode: 'TGO'},
  {shortName: 'Tokelau', twoLetterCode: 'TK', threeLetterCode: 'TKL'},
  {shortName: 'Tonga', twoLetterCode: 'TO', threeLetterCode: 'TON'},
  {
    shortName: 'Trinidad and Tobago',
    twoLetterCode: 'TT',
    threeLetterCode: 'TTO',
  },
  {shortName: 'Tunisia', twoLetterCode: 'TN', threeLetterCode: 'TUN'},
  {shortName: 'Turkey', twoLetterCode: 'TR', threeLetterCode: 'TUR'},
  {shortName: 'Turkmenistan', twoLetterCode: 'TM', threeLetterCode: 'TKM'},
  {
    shortName: 'Turks and Caicos Islands',
    twoLetterCode: 'TC',
    threeLetterCode: 'TCA',
  },
  {shortName: 'Tuvalu', twoLetterCode: 'TV', threeLetterCode: 'TUV'},
  {shortName: 'Uganda', twoLetterCode: 'UG', threeLetterCode: 'UGA'},
  {shortName: 'Ukraine', twoLetterCode: 'UA', threeLetterCode: 'UKR'},
  {
    shortName: 'United Arab Emirates',
    twoLetterCode: 'AE',
    threeLetterCode: 'ARE',
  },
  {shortName: 'United Kingdom', twoLetterCode: 'GB', threeLetterCode: 'GBR'},
  {shortName: 'United States', twoLetterCode: 'US', threeLetterCode: 'USA'},
  {
    shortName: 'United States Minor Outlying Islands',
    twoLetterCode: 'UM',
    threeLetterCode: 'UMI',
  },
  {shortName: 'Uruguay', twoLetterCode: 'UY', threeLetterCode: 'URY'},
  {shortName: 'Uzbekistan', twoLetterCode: 'UZ', threeLetterCode: 'UZB'},
  {shortName: 'Vanuatu', twoLetterCode: 'VU', threeLetterCode: 'VUT'},
  {
    shortName: 'Venezuela, Bolivarian Republic of',
    twoLetterCode: 'VE',
    threeLetterCode: 'VEN',
  },
  {shortName: 'Viet Nam', twoLetterCode: 'VN', threeLetterCode: 'VNM'},
  {
    shortName: 'Virgin Islands, British',
    twoLetterCode: 'VG',
    threeLetterCode: 'VGB',
  },
  {
    shortName: 'Virgin Islands, U.S.',
    twoLetterCode: 'VI',
    threeLetterCode: 'VIR',
  },
  {
    shortName: 'Wallis and Futuna',
    twoLetterCode: 'WF',
    threeLetterCode: 'WLF',
  },
  {shortName: 'Western Sahara', twoLetterCode: 'EH', threeLetterCode: 'ESH'},
  {shortName: 'Yemen', twoLetterCode: 'YE', threeLetterCode: 'YEM'},
  {shortName: 'Zambia', twoLetterCode: 'ZM', threeLetterCode: 'ZMB'},
  {shortName: 'Zimbabwe', twoLetterCode: 'ZW', threeLetterCode: 'ZWE'},
];
export default countries;
