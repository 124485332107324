import {Component} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {NotificationsModalComponent} from './modals/notifications/app.component';
import {PasswordModalComponent} from './modals/password/app.component';
import {DetailsModalComponent} from './modals/details/app.component';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AccountComponent {
  constructor(
    public commonService: CommonService,
    public userService: UserService,
    protected dialogRef: MatDialogRef<AccountComponent>,
    private dialog: MatDialog
  ) {}

  public editNotifications() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {};
    this.dialog.open(NotificationsModalComponent, config);
  }

  public editPassword() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {};
    this.dialog.open(PasswordModalComponent, config);
  }

  public editDetails() {
    const config = new MatDialogConfig();
    config.disableClose = true;
    this.dialog
      .open(DetailsModalComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.userService.loadUser();
      });
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}
