import { CommonModule } from '@angular/common';

import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {HeaderComponent} from './app.component';
import {SearchPanelComponent} from './search-panel/app.component';
import { UserMenuComponent } from "./user-menu/app.component";
import { ApiService } from "../../services/api.service";
import { UserService } from "../../services/user.service";
import { GazetteService } from "../../services/gazette-service";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradeModule } from "@angular/upgrade/static";
import { AppRoutingModule } from "../../../app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {CategoryMenuComponent} from './category-menu/app.component';
import {CategoryService} from '../../services/category-service';

@NgModule({
    imports: [CommonModule, HttpClientModule, BrowserModule, UpgradeModule, AppRoutingModule, FormsModule, ReactiveFormsModule],
    declarations: [HeaderComponent, SearchPanelComponent, UserMenuComponent, CategoryMenuComponent],
    exports: [HeaderComponent],
    providers: [ApiService, UserService, GazetteService, CategoryService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
