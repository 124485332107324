<div class="modal">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>Are you sure you want to delete this address?</h3>
  </section>

  <section align="end" mat-dialog-actions>
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button mat-button type="submit" (click)="onSubmit()">Confirm</button>
  </section>
</div>
