import {Component} from '@angular/core';
import {BehaviorSubject, Subscription as RxSubscription} from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../services/user.service';

@Component({
  selector: 'app-password-modal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class PasswordModalComponent {
  private Subscriptions: RxSubscription[] = [];
  public user;
  public form: FormGroup;
  public loading = false;
  public invalidPassword;

  constructor(
    protected dialogRef: MatDialogRef<PasswordModalComponent>,
    public userService: UserService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );
    this.form = this.fb.group(
      {
        passwordCurrent: [''],
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]],
      },
      {validators: this.validatePasswords}
    );
  }

  public onSubmit() {
    try {
      const data = this.form.getRawValue();
      data.id = this.user.id;
      this.loading = true;
      this.userService
        .updateUser(data)
        .then(() => {
          this.loading = false;
          this.dialogRef.close();
        })
        .catch(error => {
          const {message} = error.error.error;
          this.invalidPassword = message;
        });
    } catch (error) {
    }
  }

  public validatePasswords(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('passwordConfirm')) {
      const password = control.get('password').value;
      const passwordConfirm = control.get('passwordConfirm').value;
      return password !== passwordConfirm ? {passwordError: true} : null;
    }

    return null;
  }
}
