<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>Edit your details</h3>
    <p>To update additional details, please contact support</p>
  </section>
  <section mat-dialog-content>
    <mat-form-field>
      <mat-placeholder>First name</mat-placeholder>
      <input
        type="text"
        name="first_name"
        matInput
        formControlName="first_name"
        id="first_name"
        autocomplete="given-name"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Last name</mat-placeholder>
      <input
        type="text"
        formControlName="last_name"
        matInput
        id="last_name"
        autocomplete="family-name"
        required
      />
    </mat-form-field>
    <div>
      <p class="alert-box warning">
        Your email is used to login, if you edit this it will update your login,
        and all future notifications.
      </p>
      <mat-form-field>
        <mat-placeholder>Email</mat-placeholder>
        <input
          type="text"
          formControlName="email"
          matInput
          id="email"
          required
        />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-placeholder>Current Password</mat-placeholder>
      <input
        type="password"
        name="input"
        matInput
        formControlName="passwordCurrent"
        id="passwordCurrent"
        autocomplete="current-password"
        required
      />
    </mat-form-field>
    <p *ngIf="invalidPassword" class="form-error">
      {{ invalidPassword }}
    </p>
  </section>

  <section align="end" mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      matTooltip="Cancel and close."
      type="button"
    >
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button
      [disabled]="!form.valid || loading"
      mat-button
      matTooltip="Save product info."
      type="submit"
    >
      <mat-icon>save</mat-icon>
      Save
    </button>
  </section>
</form>
