import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'search-panel',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class SearchPanelComponent {
  @Input() mobileSearchOpen: boolean;
  @Output() mobileSearchOpenChange = new EventEmitter<boolean>();
  @Input() isLoggedIn: boolean;
  @Input() gazetteUrl: string;
  searchForm = null;
  public openCatMenu = false;

  public isMobile: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private commonService: CommonService) {
    this.searchForm = this.formBuilder.group({
      seachTerm: '',
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth <= 1055;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.isMobile = window.innerWidth <= 1055;
  }

  search() {
    this.mobileSearchOpenChange.emit(false);
    this.commonService.navigate(`/search/${this.searchForm.value.seachTerm}`);
  }
}
