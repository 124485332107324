import { enableProdMode } from '@angular/core';
import '@angular/compiler';
import 'zone.js';
import {UpgradeModule} from '@angular/upgrade/static';
import { AppModule } from './app.module';
import { platformBrowser } from '@angular/platform-browser';
import environment  from './environment/index';

if (environment.settings.stage === 'production') {
  enableProdMode();
}

platformBrowser().bootstrapModule(AppModule).then(platformRef => {
  const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
  upgrade.bootstrap(document.body, ['PartnerChoice']);
})
  .catch(err => console.error(err));
