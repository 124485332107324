import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HomepageComponent} from './components/app.component';
import {StaticCarouselComponent} from '../../component/carousel/static-carousel/app.component';
import {CommonModule} from '@angular/common';
import {HomepageService} from '../../services/homepage.service';
@NgModule({
  imports: [CommonModule],
  declarations: [HomepageComponent, StaticCarouselComponent],
  providers: [HomepageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomepageModule {}