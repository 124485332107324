import {Component} from '@angular/core';
import {filter, Subscription as RxSubscription} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrl: 'app.component.scss',
})
export class AppComponent {
  public isLoggedIn: string | boolean | null = true;
  private Subscriptions: RxSubscription[] = [];

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.Subscriptions.push(
      this.authService.isAuthenticated.subscribe(authenticated => {
        this.isLoggedIn = authenticated;
      })
    );
  }
}