<div class="page-loading" *ngIf="loading">
  <span>
      <span></span>
  </span>
</div>
<div class="my-orders grid-layout">
  <div class="row clearfix max-width">
    <div class="small-12 columns account-row">
      <a (click)="navigate('/Users/my-profile')" title="">
        <span class="fa fa-fw fa-arrow-left"></span> Back to My Account
      </a>
    </div>
  </div>

  <div class="row clearfix max-width">
    <div class="small-12 columns header-row account-row">
      <h2>Addresses</h2>
      <a class="right key" (click)="editAddress()">
        <span class="fa fa-plus"></span> Add an address
      </a>
    </div>
  </div>

  <div class="row clearfix max-width"></div>

  <div class="item" *ngFor="let address of addresses; let index = index">
    <div class="item-contents">
      <div class="description">
        <p>
          {{ address.address.address1 }}<br />
          {{ address.address.address2 }} <br />
          {{ address.address.city }}<br />
          {{ address.address.county }}<br />
          {{ getKeyByValue(address.address.country) }} <br />
          {{ address.address.postCode }}
        </p>
      </div>

      <div class="item-price address">
        <span (click)="editAddress(address)">Edit</span>
          <span class="divider">  |</span>
        <span (click)="removeAddress(address.id)"> Delete </span>
      </div>
    </div>
  </div>
</div>
