<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <section mat-dialog-title>
    <button mat-dialog-close mat-icon-button matTooltip="Close.">
      <mat-icon>close</mat-icon>
    </button>
    <h3>Edit address details</h3>
  </section>
  <section mat-dialog-content>
    <mat-form-field>
      <mat-placeholder>Address 1 *</mat-placeholder>
      <input
        type="text"
        name="address1"
        matInput
        formControlName="address1"
        id="address1"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Address 2</mat-placeholder>
      <input type="text" formControlName="address2" matInput id="address2" />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>City *</mat-placeholder>
      <input
        type="text"
        name="city"
        matInput
        formControlName="city"
        id="city"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>County *</mat-placeholder>
      <input
        type="text"
        name="county"
        matInput
        formControlName="county"
        id="county"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Country</mat-placeholder>
      <mat-select
        name="country"
        formControlName="country"
        id="country"
        required
        (selectionChange)="countryChange($event)"
      >
        <mat-option value="GB">United Kingdom</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-placeholder>Postcode *</mat-placeholder>
      <input
        type="text"
        name="postCode"
        matInput
        formControlName="postCode"
        id="postCode"
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Phone *</mat-placeholder>
      <input
        type="text"
        name="phone"
        matInput
        formControlName="phone"
        id="phone"
        required
      />
    </mat-form-field>
  </section>
  <section align="end" mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
      matTooltip="Cancel and close."
      type="button"
    >
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button
      mat-button
      [disabled]="!form.valid || loading"
      matTooltip="Save product info."
      type="submit"
    >
      <mat-icon>save</mat-icon>
      Save
    </button>
  </section>
</form>
