
<div  class="top-panel">
  <div id="mobileSearchModal" class="row collapse modal">
    <div  class="large-3 columns hide-mobile">
      <div class="row">
        <div class="row">
          <button id="mobileHamburger" class="prefix button expand modal-container">
            Menu
            <span id="mobileHamburger" class="fa fa-chevron-down"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="large-8 columns">
      <div class="row collapse-for-mobile">

        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="small-9 columns">
            <input
              type="text"
              id="search-input"
              placeholder="Search"
              formControlName="seachTerm"
            />
          </div>
          <div class="small-3 columns">
            <button
              class="button prefix to-the-top search-button"
              data-ng-click="$ctrl.doTheSearch()"
            >
              <span class="hide-for-small-only">Search </span
              ><span class="fa fa-search"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="large-1 columns lower-user-menu-button">
      <div class="row">
        <button
          class="prefix button expand"
          data-ng-click="$ctrl.panel('right')"
        >
          <span class="fa fa-user"></span>
        </button>

      </div>
    </div>
    <div class="large-1 columns lower-user-menu-button">
      <div class="row">
        <button
          class="prefix button expand"
          data-ng-click="$ctrl.panel('right')"
        >
          <span class="fa fa-user"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<category-menu [isLoggedIn]="isLoggedIn" [openCatMenu]="openCatMenu" [gazetteUrl]="gazetteUrl" />
