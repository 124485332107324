import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject} from 'rxjs';
import environment from '../../environment';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  constructor(protected Api: ApiService, protected authService: AuthService) {
    authService.isAuthenticated.subscribe(isLoggedIn => isLoggedIn ? this.loadUser() : this.User.next(null))
  }
  public User: BehaviorSubject<any> = new BehaviorSubject([]);

  public async loadUser() {
    const result = await this.Api.get(
      `${environment.settings.registeredAwsUrl}/user`
    );
    this.User.next(result.data);
  }

  public async getUserClubs() {
    return await this.Api.get(`${environment.settings.registeredAwsUrl}/clubs`);
  }

  public async updateUserPreferences(userId, data) {
    return this.Api.post(`/json/Users/${userId}/preferences`, data);
  }

  public async updateUser(data) {
    return this.Api.put(
      `${environment.settings.registeredAwsUrl}/Users/${data.id}`,
      data
    );
  }
}
