
<div
  [ngClass]="
    isLoggedIn
      ? 'wrap active-right-panel active-top-panel'
      : 'wrap active-right-panel active-top-panel guest'
  "
>
  <pc-header [isLoggedIn]="isLoggedIn"></pc-header>
  <!--Old Angularjs-->
  <div style="flex: 1">
    <router-outlet></router-outlet>
  </div>
  <!--Old Angularjs-->
  <footer [isLoggedIn]="isLoggedIn"></footer>
</div>
