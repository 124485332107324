import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import environment from '../../environment';
@Injectable()
export class HomepageService {
  constructor(protected Api: ApiService) {}

  public load() {
    return this.Api.get(
      `${environment.settings.registeredAwsUrl}/homepage`
    ).then(({data}) => {
      return data;
    });
  }
}