import {preferences, receiveEmails, tsAndCs, welcomePageUrl} from './constants';

const features = {
  notifications: {
    description:
      'Notifications give the ability to a user to see notifications for their account.',
    active: false,
  },
  ticketPdf: {
    description:
      'Users can view any tickets that have been uploaded against their orders.',
    active: false,
  },
};

const environment = {
  settings: {
    awsUrl:
      'https://jq80iwc1gj.execute-api.us-west-2.amazonaws.com/staging/registered',
    leisureUrl: 'https://staging.partnerchoice.co.uk',
    bookingUrl: 'https://deploy-preview-99--jlp-bookings.netlify.app/',
    adminUrl: 'https://deploy-preview-50--partnerchoice-admin.netlify.app/',
    mailchimpSubscribeUrl:
      'https://headforwards.us18.list-manage.com/subscribe?u=af9fdfab25d0ec40fd41feba6&id=25fae2547c',
    tsAndCs,
    welcomePageUrl,
    receiveEmails,
    debug: true,
    loginAwsUrl:
      'https://d5znqehb36.execute-api.eu-west-1.amazonaws.com/staging',
    registeredAwsUrl:
      'https://9jaqhqo45m.execute-api.eu-west-1.amazonaws.com/staging/registered',
    unregisteredAwsUrl:
      'https://hwa7b4s075.execute-api.eu-west-1.amazonaws.com/staging/unregistered',
    newAwsUrls: [],
    stage: 'staging',
  },
  features,
  preferences,
};

environment.settings.newAwsUrls = [environment.settings.registeredAwsUrl];

export default environment;
