import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../../services/user.service';
import {AddressService} from '../../../../../services/address.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import countries from '../../../../../../js/common/constant/countries.js';
import {CommonService} from '../../../../../services/common.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AddressEditModalComponent {
  public user;
  public loading = false;
  public form: FormGroup;
  public countries;
  public address;
  constructor(
    protected dialogRef: MatDialogRef<AddressEditModalComponent>,
    private addressService: AddressService,
    public commonService: CommonService,

    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.address = this.data?.address;
    this.countries = countries;

    this.form = this.fb.group({
      address1: [this.address?.address1, [Validators.required]],
      address2: [this.address?.address2, []],
      city: [this.address?.city, [Validators.required]],
      county: [this.address?.county, [Validators.required]],
      country: ['GB', [Validators.required]],
      postCode: [this.address?.postCode, [Validators.required]],
      phone: [this.address?.phone, [Validators.required]],
    });
  }

  countryChange(event) {
    this.form.controls.country.setValue(event.target.value);
  }

  getKeyByValue(value) {
    return this.countries.filter(country => country.twoLetterCode === value)[0]
      .shortName;
  }

  onSubmit() {
    try {
      const data = this.form.getRawValue();
      data.id = this.data.id;
      const {userId} = this.data;
      if (data.id) {
        this.addressService.updateAddress(userId, data).then(() => {
          this.dialogRef.close();
        });
      } else {
        this.addressService.addAddress(userId, data).then(() => {
          this.dialogRef.close();
        });
      }
    } catch (error) {
      console.log('err', error.message);
    }
  }
}
