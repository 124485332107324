<div class="grid-layout carousel is-set static-carousel">
    <div class="item" *ngFor="let item of categories">
      <div>
        <div class="item-contents">
          <a (click)="navigate('/category/' + item.slug)">
            <div class="image">
              <img
                src="{{ item.imageUrl }}"
                loading="lazy"
                alt="{{ item.title }}"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>