import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddressComponent} from './app.component';
import {AddressDeleteModalComponent} from './modals/delete/app.component';
import {AddressEditModalComponent} from './modals/edit/app.component';
import {MaterialModule} from '../../../../material.module';
@NgModule({
  declarations: [
    AddressComponent,
    AddressDeleteModalComponent,
    AddressEditModalComponent,
  ],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [AddressComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddressModule {}
