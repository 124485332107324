<div [ngClass]="!isLoggedIn ? 'logged-out-footer' : 'logged-in-footer'">
  <div class="bottom-nav-container">
    <div class="bottom-nav">
      <ul class="inline-list right">
        <li *ngIf="user.id" data-jlp-feature-active="notifications">
        </li>
        <li>
          <a
            title="Leisure Benefits facebook"
            target="_blank"
            href="https://www.facebook.com/jlppartnerchoice"
            rel="noopener noreferrer"
            ><span class="fa fa-fw fa-lg fa-facebook"></span
          ></a>
        </li>
        <li>
          <a
            title="Leisure Benefits instagram"
            target="_blank"
            href="https://www.instagram.com/partnerchoice/"
            rel="noopener noreferrer"
            ><span class="fa fa-fw fa-lg fa-instagram"></span
          ></a>
        </li>
        <li>
          <a
            title="JLP Benefits help pages"
            target="_blank"
            href="https://jlpleisurebenefits.zendesk.com/"
            rel="noopener noreferrer"
            ><span class="fa fa-fw fa-lg fa-question"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

  