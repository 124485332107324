import {Component} from '@angular/core';
import {UserService} from '../../../../services/user.service';
import environment from '../../../../../environment';
const {mailchimpSubscribeUrl} = environment.settings;
import {Subscription as RxSubscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-notifications',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class NotificationsModalComponent {
  private Subscriptions: RxSubscription[] = [];
  public user;
  public form: FormGroup;
  public loading = false;

  constructor(
    protected dialogRef: MatDialogRef<NotificationsModalComponent>,

    public userService: UserService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );

    this.form = this.fb.group({
      receive_marketing_emails: [this.user.features?.receive_marketing_emails],
      accept_terms_and_conditions: [
        !!this.user.features?.accept_terms_and_conditions,
      ],
    });
  }

  public onSubmit() {
    try {
      const data = this.form.getRawValue();
      this.loading = true;
      this.userService.updateUserPreferences(this.user.id, data).then(() => {
        this.loading = false;
        this.dialogRef.close();
      });
    } catch (error) {}
  }

  public mailchimpPopup() {
    const {email, first_name: firstName, last_name: lastName} = this.user;

    const url = [
      `${mailchimpSubscribeUrl}`,
      `MERGE0=${email}`,
      `MERGE1=${firstName}`,
      `MERGE2=${lastName}`,
    ].join('&');
    console.log(url);
    window.open(url, 'receive marketing emails', 'menubar=0');
    window.close();
  }
}
