import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'static-carousel',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class StaticCarouselComponent {
  constructor(private router: Router, public commonService: CommonService) {}
  public categories = [
    {
      title: 'Body',
      slug: 'body',
      parent_id: null,
      font_icon: '',
      kind: 'offers',
      description: '<p>Body category</p>',
      id: 84,
      imageUrl: '/app/assets/img/homepage/Body_PC_Homepage.jpg',
    },
    {
      id: 86,
      title: 'Mind',
      slug: 'mind',
      kind: 'offers',
      parent_id: null,
      font_icon: null,
      imageUrl: '/app/assets/img/homepage/Mind_PC_Homepage.jpg',
    },
    {
      id: 85,
      title: 'Life',
      slug: 'life',
      kind: 'offers',
      parent_id: null,
      font_icon: null,
      imageUrl: '/app/assets/img/homepage/Life_PC_Homepage.jpg',
    },

    {
      id: 87,
      title: 'Soul',
      slug: 'soul',
      kind: 'offers',
      parent_id: null,
      font_icon: null,
      imageUrl: '/app/assets/img/homepage/Soul_PC_Homepage.jpg',
    },
  ];

  public navigate(path) {
    this.commonService.navigate(path);
  }
}