import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'test',
  templateUrl: 'app.component.html',
  styleUrl: 'app.component.scss',
})
export class TestComponent implements OnInit {
  ngOnInit(): void {
    console.log('TestComponent has been initialized');
  }
 
}
