import {Component} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {UserService} from '../../../services/user.service';
import countries from '../../../../js/common/constant/countries.js';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {AddressDeleteModalComponent} from './modals/delete/app.component';
import { AddressEditModalComponent } from './modals/edit/app.component';
@Component({
  selector: 'address-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AddressComponent {
  public addresses;
  public countries;
  public loading: boolean;
  constructor(
    protected userService: UserService,
    public commonService: CommonService,
    protected dialogRef: MatDialogRef<AddressComponent>,
    private dialog: MatDialog,
  ) {
    this.loading = true;
    this.userService.User.subscribe(({User}) => {
      this.addresses = User.UserAddress;
      this.countries = countries;
      this.loading = false;
    });
  }

  getKeyByValue(value) {
    return this.countries.filter(country => country.twoLetterCode === value)[0]
      .shortName;
  }

  removeAddress(addressId) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = {id: addressId};
    this.dialog
      .open(AddressDeleteModalComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.userService.loadUser();
      });
  }

  editAddress(address?) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.data = address || {};
    config.data.userId = this.userService.User.value.User.id;
    this.dialog
      .open(AddressEditModalComponent, config)
      .afterClosed()
      .subscribe(() => {
        this.userService.loadUser();
      });
  }

  public navigate(path) {
    this.commonService.navigate(path);
  }
}
