<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="form receive_marketing_emails"
>
  <div id="modal">
    <div class="modal-content">
      <div class="modal-container">
        <div class="header" data-ng-hide="false">
          <button
            mat-dialog-close
            mat-icon-button
            matTooltip="Close."
            class="closeButton"
          >
            <span class="fa fa-fw fa-times"></span>
          </button>
          <h3>Edit your user details</h3>
        </div>
        <div class="body">
          <div class="modal-body">
            <p>
              We would like to send you information about products, services,
              tickets and events of ours and other companies that we offer
              benefits through, which may be of interest to you. You have a
              right at any time to stop us from contacting you for marketing
              purposes.
            </p>
            <label class="checkbox-with-text">
              <!-- <input
                *ngIf="user.features.mailchimp?.webhook"
                type="checkbox"
                (click)="mailchimpPopup()"
              /> -->
              <mat-checkbox
                *ngIf="!user.features?.mailchimp?.webhook"
                type="checkbox"
                formControlName="receive_marketing_emails"
                />
              Yes, I would like to receive information about Partnership events,
              flash sales, Partner extravaganzas, Club news, offers, tickets and
              so much more...
            </label>
          </div>
        </div>
        <div class="footer">
          <button
            mat-button
            mat-dialog-close
            matTooltip="Cancel and close."
            type="button"
          >
            Cancel
          </button>
          <button mat-button matTooltip="Save product info." type="submit">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
