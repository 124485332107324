import {Component, Input} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Subscription as RxSubscription} from 'rxjs';

@Component({
  selector: 'footer',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class FooterComponent {
  public user = {id: null};
  @Input() isLoggedIn: string | boolean | null;
}
