import {Component} from '@angular/core';
import {BehaviorSubject, Subscription as RxSubscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../services/user.service';
@Component({
  selector: 'app-details',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class DetailsModalComponent {
  private Subscriptions: RxSubscription[] = [];
  public user;
  public form: FormGroup;
  public loading = false;
  public showEmailFields: boolean = false;
  public invalidPassword;

  constructor(
    protected dialogRef: MatDialogRef<DetailsModalComponent>,
    public userService: UserService,
    public fb: FormBuilder
  ) {}

  ngOnInit() {
    this.Subscriptions.push(
      this.userService.User.subscribe(data => (this.user = data.User))
    );
    this.form = this.fb.group(
      {
        first_name: [this.user?.first_name, [Validators.required]],
        last_name: [this.user?.last_name, [Validators.required]],
        email: [this.user?.email, [Validators.required]],
        passwordCurrent: ['', [Validators.required]],
      }
    );
  }

  onSubmit() {
    try {
      const data = this.form.getRawValue();
      data.id = this.user.id;
      this.loading = true;
      this.userService
        .updateUser(data)
        .then(() => {
          this.loading = false;
          this.dialogRef.close();
        })
        .catch(error => {
          const {message} = error.error.error;
          this.invalidPassword = message;
        });
    } catch (error) {
      console.log('err', error.message);
    }
  }
}
